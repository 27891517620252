import type { FetchError } from 'ofetch'
import type { FormSubmitContext } from '~/ts/types/form'

type Arguments = {
    error: MaybeRef<FetchError>
    setFieldError: FormSubmitContext['setFieldError']
    handleStatusError?: Record<string, (error: MaybeRef<FetchError>) => void>
    handleFieldError?: Record<string, (message: string) => void>
}

export default ({ error, setFieldError, handleStatusError, handleFieldError }: Arguments): void => {
    const errorValue = toValue(error)

    if (handleStatusError?.[errorValue.statusCode]) {
        handleStatusError[errorValue.statusCode](errorValue)
    } else if (errorValue.statusCode === 422) {
        for (const index in errorValue.data) {
            const { field, message } = errorValue.data[index]

            if (handleFieldError?.[field]) {
                handleFieldError[field](message)
            } else {
                setFieldError(field, message)
            }
        }
    } else {
        useLog(
            'Unexpected or unhandled error status - ' + errorValue.statusCode,
            'handleFormError',
            { error: true, devOnly: true },
        )
    }
}
